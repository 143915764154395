import React, { useEffect, useState } from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Header from './Header';
import TopMenu from './TopMenu';
import GlobalStyle from '../style/globalStyle';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import lodashCloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
    getContextRoot,
    getLanguageValue,
    getApiRoot,
    googleRecaptcha,
    getStatusHtml,
    navigateToPath,
    getExtensionByFileContent,
    getFileType,
    executeSingleRequest,
    notifyClientError, convertDateFieldsIntoString, getCountriesList
} from "../common/functions";
import CONSTANTS from '../common/constants';
import HeaderWithLogoBackground from "./HeaderWithLogoBackground";
import MainFooter from "./MainFooter";
import TableComponent from './TableComponent';
import { useParams } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import Loader from './Loader';
import { ACTIONS } from '../redux/actions';
import { CSS_COLORS } from '../common/cssColors';
import CropHandler from './CropHandler';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import ModalComponent from "./Modal";
import GenericFieldComponent from "./GenericFieldComponent";

function UpdateRejectedDataBodyComponent(props) {

    const dispatch = useDispatch();
    const isMobile = useMediaQuery(`(max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px)`);
    const [ isUpdateCompleted, setIsUpdateCompleted ] = useState(false);
    const [ loaders, setLoaders ] = useState({ init: true });
    const [ fieldErrors, setFieldErrors ] = useState({ });
    const [ fieldsData, setFieldsData ] = useState(lodashGet(props, 'rejectedApplicationData.fields') || {});
    let [ fileUploadsData, setFileUploadsData ] = useState(getUploadedFiles());
    let [ cropHandlerControls , setCropHandlerControls ] = useState({
        passport: {},
        passportFace: {},
        birthCertificate: {},
        parentPassport: {}
    });
    cropHandlerControls.passport.callback = cropHandlerCallback.bind({ fileType: 'passport' });
    cropHandlerControls.passportFace.callback = cropHandlerCallback.bind({ fileType: 'passportFace' });
    cropHandlerControls.birthCertificate.callback = cropHandlerCallback.bind({ fileType: 'birthCertificate' });
    cropHandlerControls.parentPassport.callback = cropHandlerCallback.bind({ fileType: 'parentPassport' });
    let [ pdfHandlerControls , setPdfHandlerControls ] = useState({ // eslint-disable-line no-unused-vars
        passport: {},
        passportFace: {},
        birthCertificate: {},
        parentPassport: {}
    });
    const [ isModalOpened, setIsModalOpened] = useState(false);

    const FIELDS = [
        {
            key: 'stage2FirstNameEnglish',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.stage2FirstNameEnglish ? ' field-error' : ''),
                    error: fieldErrors.stage2FirstNameEnglish || false,
                    value: fieldsData.stage2FirstNameEnglish,
                    inputProps: {
                        name: 'stage2FirstNameEnglish'
                    },
                    helperText: fieldErrors.stage2FirstNameEnglish || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'stage2FirstNameEnglish'}),
                    label: getLanguageValue('register-individual.stage2-first-name-english') + ' *'
                }
            }
        },
        {
            key: 'stage2LastNameEnglish',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.stage2LastNameEnglish ? ' field-error' : ''),
                    error: fieldErrors.stage2LastNameEnglish || false,
                    value: fieldsData.stage2LastNameEnglish,
                    inputProps: {
                        name: 'stage2LastNameEnglish'
                    },
                    helperText: fieldErrors.stage2LastNameEnglish || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'stage2LastNameEnglish'}),
                    label: getLanguageValue('register-individual.stage2-family-name-english') + ' *'
                }
            }
        },
        {
            key: 'stage2BirthDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#stage2BirthDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.stage2BirthDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.stage2BirthDate || false,
                    autoOk: true,
                    value: fieldsData.stage2BirthDate !== '' ? fieldsData.stage2BirthDate : null,
                    inputProps: {
                        name: 'stage2BirthDate',
                        id: 'stage2BirthDate'
                    },
                    helperText: fieldErrors.stage2BirthDate || '',
                    onChange: (value) => {
                        DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'stage2BirthDate' })(value);
                    },
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'stage2BirthDate'}),
                    label: getLanguageValue('register-individual.stage2-date-of-birth') + ' *'
                }
            }
        },
        {
            key: 'stage2PassportNumber',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: props.languageKey == 'he' ? DEFAULT_NUMERIC_VALUE_VALIDATOR : DEFAULT_ENG_NUMERIC_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.stage2PassportNumber ? ' field-error' : ''),
                    error: fieldErrors.stage2PassportNumber || false,
                    value: fieldsData.stage2PassportNumber,
                    inputProps: {
                        name: 'stage2PassportNumber'
                    },
                    helperText: fieldErrors.stage2PassportNumber || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'stage2PassportNumber'}),
                    label: getLanguageValue('register-individual.stage2-passport-number') + ' *'
                }
            }
        },
        {
            key: 'stage2CountryOfBirth',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'stage2CountryOfBirth',
                selectLabel: getLanguageValue('register-individual.stage2-country-of-birth') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.stage2CountryOfBirth ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.stage2CountryOfBirth || false,
                    value: fieldsData.stage2CountryOfBirth,
                    inputProps: {
                        name: 'stage2CountryOfBirth',
                        id: 'stage2CountryOfBirth'
                    },
                    helperText: fieldErrors.stage2CountryOfBirth || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'stage2ExpirationDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#stage2ExpirationDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: (value) => { let currentDate = moment(moment().add('3','months').format('YYYY-MM-DD')); value = document.querySelector('#expirationDate').value; if (moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid() && moment(value, 'DD/MM/YYYY').diff(currentDate) <= 0) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-expiration-date-must-be-above-x-months')
                },
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.stage2ExpirationDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.stage2ExpirationDate || false,
                    autoOk: true,
                    value: fieldsData.stage2ExpirationDate !== '' ? fieldsData.stage2ExpirationDate : null,
                    inputProps: {
                        name: 'stage2ExpirationDate',
                        id: 'stage2ExpirationDate'
                    },
                    helperText: fieldErrors.stage2ExpirationDate || '',
                    onChange: (value) => {
                        DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'stage2ExpirationDate' })(value);
                    },
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'stage2ExpirationDate'}),
                    label: getLanguageValue('register-individual.stage2-expiration-date') + ' *'
                }
            }
        },
        {
            key: 'stage2IssuanceDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#stage2IssuanceDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.stage2IssuanceDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.stage2IssuanceDate || false,
                    autoOk: true,
                    value: fieldsData.stage2IssuanceDate !== '' ? fieldsData.stage2IssuanceDate : null,
                    inputProps: {
                        name: 'stage2IssuanceDate',
                        id: 'stage2IssuanceDate'
                    },
                    helperText: fieldErrors.stage2IssuanceDate || '',
                    onChange: DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'stage2IssuanceDate' }),
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'stage2IssuanceDate'}),
                    label: getLanguageValue('register-individual.stage2-issuance-date') + ' *'
                }
            }
        }
    ];

    const FILES_FIELDS = [
        {
            key: 'passport',
            isDisplayed: !!fileUploadsData.passport,
            warningMessage: getLanguageValue('register-individual.stage2-upload-general-warning'),
            updateButtonText: getLanguageValue('update-rejected-data.files-passport') + ' *',
            sampleImageUrl: getContextRoot() + 'images/sample_passport.jpg'
        },
        {
            key: 'passportFace',
            isDisplayed: !!fileUploadsData.passportFace,
            warningMessage: getLanguageValue('register-individual.stage2-upload-passport-face-note'),
            updateButtonText: getLanguageValue('update-rejected-data.files-passportFace') + ' *',
            sampleImageUrl: getContextRoot() + 'images/sample_passport_face.jpg'
        },
        {
            key: 'birthCertificate',
            isDisplayed: !!fileUploadsData.birthCertificate,
            warningMessage: getLanguageValue('register-individual.stage2-upload-birth-certificate-note'),
            updateButtonText: getLanguageValue('update-rejected-data.files-birthCertificate') + ' *',
            sampleImageUrl: getContextRoot() + 'images/sample_birth_certificate.jpg'
        },
        {
            key: 'parentPassport',
            isDisplayed: !!fileUploadsData.parentPassport,
            warningMessage: false,
            updateButtonText: getLanguageValue('update-rejected-data.files-parentPassport') + ' *',
            sampleImageUrl: getContextRoot() + 'images/sample_passport.jpg'
        }
    ];

    function DEFAULT_ON_BLUR() {
        let matchedField = FIELDS.find((item) => item.key == this.key);
        if (lodashGet(matchedField, 'validators')) {
            let fieldValue = lodashGet(matchedField, 'props.fieldProps.value');
            for (let validatorObj of lodashGet(matchedField, 'validators')) {
                if (!validatorObj.test(fieldValue)) {
                    notifyClientError({ data: fieldsData, errors: { ...fieldErrors, [this.key] : validatorObj.errorMessage } });
                    setFieldErrors({ ...fieldErrors, [this.key] : validatorObj.errorMessage });
                    return;
                }
            }
        }
    }

    function DEFAULT_DATE_ON_CHANGE(date) {
        if (moment(date, true).isValid()) {
            lodashSet(fieldsData, this.fieldName, date);
            setFieldsData({ ...fieldsData });
            setFieldErrors({ ...fieldErrors, [this.fieldName] : null });
        }
        else {
            lodashSet(fieldsData, this.fieldName, null);
            setFieldsData({...fieldsData });
        }
    }

    function DEFAULT_EMPTY_VALUE_VALIDATOR(value) {
        if (!value) {
            return false;
        }
        return true;
    }

    function DEFAULT_NUMERIC_VALUE_VALIDATOR(value) {
        if (!/^[0-9]+$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ENG_NUMERIC_VALUE_VALIDATOR(value) {
        if (!/^(([0-9]+)|([a-zA-Z]+))+$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ENG_STRING_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([a-zA-Z\"\'\.\(\)\-,\s]+)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ON_CHANGE(e) {
        setFieldsData({...fieldsData, [e.target.name] : e.target.value });
        setFieldErrors({ ...fieldErrors, [e.target.name] : null });
    }

    useEffect(() => {
        setTimeout(() => {
            // quit the page if no data available on redux
            if (!lodashGet(props, 'rejectedApplicationData.formKey')) {
                navigateToPath(dispatch, getContextRoot());
                return;
            }

            // set all images to edit-mode by default
            const filesKeysList = [ 'passport', 'passportFace', 'birthCertificate', 'parentPassport'];
            for (let filesKey of filesKeysList) {
                if (fileUploadsData[filesKey]) {
                    cropHandlerControls[filesKey].restart();
                }
            }
        }, 10);
    }, []);

    return <>
        <GlobalStyle/>
        <UpdateRejectedDataBodyStyle>
            <Header className="header-component"/>
            <HeaderWithLogoBackground className="header-background-component" />
            <TopMenu className="top-menu-component" />
            <div className={"content-container"}>
                <h1>{getLanguageValue('update-rejected-data.title')} { isMobile ? <br/> :  null } <span className={'form-key-text'}>{lodashGet(props, 'rejectedApplicationData.formKey')}</span></h1>
                {lodashGet(props, 'rejectedApplicationData.groupKey') && <h2>{getLanguageValue('update-rejected-data.group-form')} - {lodashGet(props, 'rejectedApplicationData.groupKey')}</h2>}
                <h2>{getLanguageValue('update-rejected-data.fields-to-fix')}</h2>
                <Grid container className="fields-to-fix-container">
                    {Object.keys(fieldsData).length == 0 && getLanguageValue('update-rejected-data.nothing-required')}                    
                    {Object.keys(fieldsData).length > 0 && Object.keys(fieldsData).map((fieldItemKey, fieldItemKeyIndex) => {
                        //let fieldKeyPart = fieldItemKey.substring(6, 7).toLowerCase() + fieldItemKey.substring(7);
                        return <Grid item key={fieldItemKeyIndex} className={"single-field-container"} md={4}>
                            {<GenericFieldComponent {...FIELDS.find(fieldConfig => fieldConfig.key == fieldItemKey).props} />}
                        </Grid>;
                    })}
                </Grid>
                <h2>{getLanguageValue('update-rejected-data.files-to-fix')}</h2>
                <div className="files-to-fix-container">
                    {FILES_FIELDS.filter(fileFieldItem => fileFieldItem.isDisplayed).length == 0 && getLanguageValue('update-rejected-data.nothing-required')}
                    {FILES_FIELDS.map((fileFieldItem, fileFieldItemIndex) => fileFieldItem.isDisplayed && <div key={fileFieldItemIndex} className={`file-to-fix-item ${fileFieldItem.key}`}>
                        {!!fileFieldItem.warningMessage && <>
                            <div className="upload-general-warning-message" dangerouslySetInnerHTML={{ __html: fileFieldItem.warningMessage }} />
                            </>}
                        <Button className={"file-upload-button " + fileFieldItem.key + (fieldErrors[fileFieldItem.key] ? ' field-error' : '')} onClick={() => { document.querySelector(`#${fileFieldItem.key}-file-upload-field`).click(); }}>{fileFieldItem.updateButtonText}</Button>
                        {fieldErrors[fileFieldItem.key] ? <div className={"file-upload-error"}>{fieldErrors[fileFieldItem.key]}</div> : null}
                        <br/><br/>
                        <input type="file" id={`${fileFieldItem.key}-file-upload-field`} className={'file-upload-field ' + fileFieldItem.key} onChange={(e) => { onFileUploadChange(e, fileFieldItem.key, `.file-to-fix-item.${fileFieldItem.key}`); }} />
                        <div className={"image-component-container " + fileFieldItem.key}><img className={"image-component " + fileFieldItem.key} src={fileUploadsData[fileFieldItem.key].fileContent} /></div>
                        <br/>
                        {lodashGet(fileUploadsData[fileFieldItem.key], 'fileContent') && !lodashGet(fileUploadsData[fileFieldItem.key], 'editMode') ? <div><Button className={"file-upload-edit-button"} onClick={() => { lodashSet(fileUploadsData[fileFieldItem.key], 'editMode', true); cropHandlerControls[fileFieldItem.key].restart(); setFieldsData({ ...fieldsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                        <CropHandler controls={cropHandlerControls[fileFieldItem.key]} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS[fileFieldItem.key + 'Mobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS[fileFieldItem.key + 'Desktop']} imageCssSelector={'.image-component.' + fileFieldItem.key} className={"crop-handler " + fileFieldItem.key} imageSrc={lodashGet(fileUploadsData[fileFieldItem.key], 'fileContent')} />
                        <br/>
                        {!!fileFieldItem.sampleImageUrl && <img className={"sample-image " + fileFieldItem.key} src={fileFieldItem.sampleImageUrl} />}
                    </div>)}
                </div>
                <br/><br/><br/>
                <div className="buttons-container">
                    <Button disabled={isUpdateCompleted} className="general-style-update-button" onClick={() => { update(); }}>{getLanguageValue('register-common.stages-buttons-update')} {lodashGet(loaders, 'update') ? <Loader className={"update-loader"} /> : null}</Button>
                </div>
                {fieldErrors.update ? <div className={"update-error"}>{fieldErrors.update}</div> : null}
                <br/>
            </div>
            <ModalComponent
                isOpen={isModalOpened}
                modalClassName={'update-rejected-data-modal'}
                showTitleCloseButton={true}
                onClose={(src) => { setIsModalOpened(false) }}
                title={getLanguageValue('update-rejected-data.completed-title')}
                content={<ModalComponentStyle>
                    <ModalOverrideGlobalStyle />
                    <div className={'html-container'}>{getLanguageValue('update-rejected-data.completed-content')}</div>
                    <div id="modal-buttons"><Button className="close-button" onClick={() => { setIsModalOpened(false); }}>{getLanguageValue('need-help-section.modal-close')}</Button></div>
                </ModalComponentStyle>}
            />
            <MainFooter className="footer-component" />
        </UpdateRejectedDataBodyStyle>
    </>;


    function isLoading() {
        for (let key in loaders) {
            if (loaders[key]) {
                return true;
            }
        }
        return false;
    }

    async function update() {
        let validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        setLoaders({ ...loaders, update: true });
        try {
            // create separate file upload requests (one by one)
            let promises = [];
            for (let fileKey in fileUploadsData) {
                delete fileUploadsData[fileKey].changed;
                delete fileUploadsData[fileKey].editMode;
                let boundPromise = executeSingleRequest.bind({
                    url: getApiRoot() + 'update-rejected-data/?type=' + (props.rejectedApplicationData.groupKey ? 'group' : 'individual') + '&languageKey=' + props.languageKey,
                    requestBody: {
                        key: props.rejectedApplicationData.formKey,
                        groupKey: props.rejectedApplicationData.groupKey,
                        data: {
                            fields: {}, // no fields
                            files: {
                                [fileKey]: fileUploadsData[fileKey]
                            }
                        }
                    }
                });
                // push final results
                promises.push(await boundPromise());
            }
            let duplicateData = lodashCloneDeep(fieldsData);
            convertDateFieldsIntoString(duplicateData, 'YYYY-MM-DD');
            if (Object.keys(duplicateData).length) {
                let boundPromise = executeSingleRequest.bind({
                    url: getApiRoot() + 'update-rejected-data/?type=' + (props.rejectedApplicationData.groupKey ? 'group' : 'individual') + '&languageKey=' + props.languageKey,
                    requestBody: {
                        key: props.rejectedApplicationData.formKey,
                        groupKey: props.rejectedApplicationData.groupKey,
                        data: {
                            fields: duplicateData,
                            files: {}
                        }
                    }
                });
                // push final results
                promises.push(await boundPromise());
            }

            try {
                setFieldErrors({ ...fieldErrors, update: false });
                let results = promises;
                setLoaders({ ...loaders, update: false });
                for (let result of results) {
                    if (!lodashGet(result, 'data.success')) {
                        setFieldErrors({ ...fieldErrors, update: getLanguageValue('register-network-error') });
                        return;
                    }
                }

                // update redux and finish
                setIsUpdateCompleted(true);
                setIsModalOpened(true);

                //props.rejectedApplicationData.formKey
                if (props.rejectedApplicationData.groupKey) {
                    // group form
                    let isMatch = false;
                    for (let loop = 0 ; loop < props.linkedApplicationsData.length && !isMatch ; ++loop) {
                        if (props.linkedApplicationsData[loop].key == props.rejectedApplicationData.formKey) {
                            // matched
                            isMatch = true;
                            props.linkedApplicationsData[loop].rejectReasons = {};
                            props.linkedApplicationsData[loop].status = 'waiting-for-agent';
                        }
                    }
                    dispatch({
                        type: ACTIONS.GENERIC_SET_VALUE,
                        payload: [
                            {
                                path: 'rejectedApplicationData',
                                value: null
                            },
                            {
                                path: 'registerGroupStagesData.stage2Data.linkedIndividualApplications',
                                value: [ ...props.linkedApplicationsData ]
                            }]
                    });
                }
                else {
                    // individual form
                    dispatch({
                        type: ACTIONS.GENERIC_SET_VALUE,
                        payload: [
                            {
                                path: 'rejectedApplicationData',
                                value: null
                            },
                            {
                                path: 'registerIndividualStagesData.rejectReasons',
                                value: {}
                            },
                            {
                                path: 'registerIndividualStagesData.status',
                                value: 'waiting-for-agent'
                            }]
                    });
                }
            }
            catch (err) {
                // nothing to do
                setLoaders({ ...loaders, update: false });
                setFieldErrors({ ...fieldErrors, update: getLanguageValue('register-network-error') });
                return;
            }
        }
        catch (err) {
            setLoaders({ ...loaders, update: false });
            setFieldErrors({ ...fieldErrors, update: getLanguageValue('register-network-error') });
        }
    }

    function getUploadedFiles() {
        let output = {};
        if (lodashGet(props, 'rejectedApplicationData.files') && Object.keys(props.rejectedApplicationData.files).length) {
            for (let fileType in props.rejectedApplicationData.files) {
                output[fileType] = {
                    ...props.rejectedApplicationData.files[fileType],
                    changed: false,
                    editMode: true
                };
            }
        }
        return output;
    }

    function onFileUploadChange(e, fieldName, containerCssSelector) {
        const file = e.target.files[0];
        const localFilename = file.name;
        const fileExtension = localFilename.split('\.')[localFilename.split('\.').length - 1].toLowerCase();
        const reader = new FileReader();
        // reset to allow selecting same filename
        e.target.value = '';

        reader.addEventListener("load", async function () {
            // validate
            let fileTypeResult = await getFileType(reader.result);
            let fileTypeResultExtension = lodashGet(fileTypeResult, 'ext');
            if (['jpg', 'jpeg', 'png', 'bmp', 'heic'].indexOf(fileTypeResultExtension) == -1) {
                setFieldErrors({ ...fieldErrors, [fieldName]: getLanguageValue('register-individual.stage2-upload-passport-type-error') });
                setFileUploadsData({ ...fileUploadsData, [fieldName]: { fileContent: '' } });
                return;
            }
            if (reader.result.length < CONSTANTS.FILESIZE_MIN_LIMIT) {
                setFieldErrors({ ...fieldErrors, [fieldName]: getLanguageValue('register-individual.stage2-upload-passport-too-small-error') });
                setFileUploadsData({ ...fileUploadsData, [fieldName]: { fileContent: '' } });
                return;
            }
            if (reader.result.length > CONSTANTS.FILESIZE_MAX_LIMIT) {
                setFieldErrors({ ...fieldErrors, [fieldName]: getLanguageValue('register-individual.stage2-upload-passport-size-error') });
                setFileUploadsData({ ...fileUploadsData, [fieldName]: { fileContent: '' } });
                return;
            }
            setFieldErrors({ ...fieldErrors, [fieldName]: false });
            // close previous crop tool if have any
            if (document.querySelector(containerCssSelector + ' .image-crop-buttons-container .crop-finish-button')) {
                document.querySelector(containerCssSelector + ' .image-crop-buttons-container .crop-finish-button').click();
            }
            // activate crop tool
            setTimeout(() => {
                setFileUploadsData({...fileUploadsData, [fieldName]: { fileContent: reader.result, fileExtension: getExtensionByFileContent(reader.result), fileType: fieldName, editMode: true, changed: true } });
                setTimeout(() => {
                    cropHandlerControls[fieldName].restart();
                }, 100);
            }, 100);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    function validate(config) {
        let output = [];
        let localErrors = {};
        // check files errors
        let filesList = [ 'passport', 'passportFace', 'birthCertificate', 'parentPassport' ];
        for (let fileKey of filesList) {
            if (fieldErrors[fileKey]) {
                output.push(fileKey);
            }
            else if (lodashGet(fileUploadsData, fileKey) && !lodashGet(fileUploadsData, fileKey + '.changed')) {
                output.push(fileKey);
                if (lodashGet(config, 'setErrors')) {
                    localErrors[fileKey] = getLanguageValue('register-field-errors-must-upload');
                }
            }
        }
        // check fields errors
        let duplicateData = lodashCloneDeep(fieldsData);
        convertDateFieldsIntoString(duplicateData, 'YYYY-MM-DD');
        for (let key in duplicateData) {
            let newValue = duplicateData[key];
            let fieldStage = key.substring(0, 6);
            let fieldStageKey = key.substring(6,7).toLowerCase() + key.substring(7);
            let originalValue = lodashGet(props.individualStagesData, fieldStage + 'Data.' + fieldStageKey);
            console.log('checking...', key, 'newValue', newValue, 'originalValue', originalValue );
            if (newValue == originalValue) {
                output.push(key);
                localErrors[key] = getLanguageValue('register-field-errors-must-change-value');
            }
        }
        if (lodashGet(config, 'setErrors') && Object.keys(localErrors).length) {
            setFieldErrors(localErrors);
        }
        return output;
    }

    function cropHandlerCallback(croppedImage) {
        lodashSet(fileUploadsData[this.fileType], 'editMode', false);
        setFieldErrors({ ...fieldErrors, [this.fileType]: false });
        if (croppedImage !== false) {
            fileUploadsData[this.fileType].fileContent = croppedImage;
            lodashSet(fileUploadsData[this.fileType], 'changed', true);
        }
        setFileUploadsData({ ...fileUploadsData });
    }
}

const ModalComponentStyle = styled.div`
  #modal-buttons {
    background-color: lightgray;
    text-align: center;
    padding: 7px;
  }
  .close-button {
    background-color: #fc3;
  }
  .modal-bottom-spacer {
    height: 20px;
  }
`;

const ModalOverrideGlobalStyle = createGlobalStyle`
  #modal-component.update-rejected-data-modal {
    #modal-title {
      font-size: 33px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        font-size: 24px;
      }
    }
    #modal-content {
      padding: 0 !important;
      font-size: 22px;
    }
    .html-container {
      padding: 10px 10px;
    }
  }
`;

const UpdateRejectedDataBodyStyle = styled.div`
  .content-container {
    padding-right: 20px;
    padding-left: 20px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      padding: 20px 10%;
    }
  }
  h1 {
    text-align: center;
    .form-key-text {
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        font-size: 25px;
      }
    }
  }
  .single-field-container {
    margin-bottom: 25px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        width: 100%;
    }
  }
  .field-component {
    width: calc(100% - 20px);
    html.rtl & {
      margin-left: 20px;
    }
    html.ltr & {
      margin-right: 20px;
    }
  }
  .loader-container {
    text-align: center;
  }
  .sample-image {
    max-width: 100%;
  }
  .upload-general-warning-message {
    max-width: 500px;
    color: ${CSS_COLORS.FIELD_WARNING};
    margin-bottom: 5px;
    text-align: right;
    html.ltr & {
      text-align: left;
    }
  }
  .image-component-container {
    max-width: 500px;
  }
  .image-component-container.passport .image-component {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      max-width: 100%;
      height: auto;
    }
  }
  .image-component-container.passportFace .image-component {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      max-width: 100%;
      height: auto;
    }
  }
  .image-component-container.birthCertificate .image-component {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      max-width: 100%;
      height: auto;
    }
  }
  .image-component-container.parentPassport .image-component {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      max-width: 100%;
      height: auto;
    }
  }
  .file-upload-button {
    width: 500px;
    height: 50px;
    border: 1px solid black;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
    &.field-error {
      border-color: ${CSS_COLORS.FIELD_WARNING};
      color: ${CSS_COLORS.FIELD_WARNING};
    }
  }
  .file-upload-error {
    color: ${CSS_COLORS.FIELD_WARNING};
    margin-top: 15px;
  }
  .file-upload-field {
    display: none;
  }
  .file-upload-edit-button {
    width: 500px;
    border: 1px solid black;
    &.passportFace {
      max-width: 350px;
    }
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100% !important;
    }
  }
  .image-component {
    border: 1px dashed black;
    &.passport {
      max-width: 500px;
    }
    &.passportFace {
      max-width: 350px;
    }
    &.birthCertificate {
      max-width: 600px;
    }
    &.parentPassport {
      max-width: 500px;
    }
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100% !important;
    }
  }
  .image-component-container {
    width: 100%;
  }
  .update-loader {
    color: white;
  }
  .update-error {
    margin-top: 10px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
`;


const UpdateRejectedDataBody = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        rejectedApplicationData: state.rejectedApplicationData,
        individualStagesData: lodashGet(state, 'registerIndividualStagesData'),
        linkedApplicationsData: lodashGet(state, 'registerGroupStagesData.stage2Data.linkedIndividualApplications') || [],
    }),
    {})(UpdateRejectedDataBodyComponent);

export default UpdateRejectedDataBody;
